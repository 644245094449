<template>
	<div class="home">
		<div id="content" class="home-content blue-box curved" style="--accent: #00a7e5">
			<svg width="500" height="200" viewBox="0 0 500 200" preserveAspectRatio="none">
				<path d="M0,200 L0,26 Q250,-16 500,26 L500,200 Q250,160 0,200Z" fill="#cee7f0"/>
				<path d="M0,30 L0,25 Q250,-15 500,25 L500,30 Q250,-10 0,30Z" fill="#00a7e5"/>
			</svg>

            <div class="content-wide centered-banner">
				<p class="banner-title-handwriting">About Us</p>
            </div>
		</div>
        <PhotoRow :set="7" :max="7" style="margin-top: -100px"></PhotoRow>

        <div id="content">
            <p class="info-bold">Encore Music Education provides a world-class music curriculum used by teachers, students and music schools around the world. We are an Australian owned and operated business based in Brisbane.</p>
            <p class="info">We have been training music teachers and producing music education courses since 1997. Accent Publishing Pty Ltd is our publishing and distribution company for all the Encore materials. We are an experienced team, who strive to ensure that our resources provide an enriching experience for teachers, students and parents. Encore Music Education and Accent Publishing are proud to support the Australian music industry and other small businesses by engaging local professionals for our recording, printing, illustrating, website and app development projects.</p>

            <p class="heading">Our Authors</p>

            <p class="info">
                Mark Gibson and Morna Robinson are the innovative team behind Accent Publishing and Encore Music Education. Their musical partnership spans more than 35 years of friendship and collaboration. They understand the enormous contribution that music makes to the well-being of children. These benefits include cognitive, emotional, social and physical development. Mark and Morna continue to be driven by a shared vision to research and develop music education resources of the highest quality.
            </p>

            <p class="heading-2">Mark Gibson</p>
            <p class="info-bold">MMus, BMus(Dist), GradDipMus, GradDipFET, FTCL, LTCL, AMusA</p>

            <p class="info">
                Mark has a reputation as being one of Australia’s most outstanding music educators. He fulfils a demanding schedule internationally as an examiner, composer, adjudicator, master teacher, workshop presenter and curriculum writer.  His dedication and passion are the driving force behind Encore Music Education and Accent Publishing.
            </p>

            <p class="info">
                The Queensland Conservatorium of Music, Australia, has produced many outstanding graduates and Mark is privileged to be among them.  His principal piano teacher was the renowned pedagogue and pianist Dr Max Olding AM. Other distinguished teachers included Pamela Page, David Bollard and Una Morgan, whose musical pedigree links directly to Clara Schumann. Mark’s instrumental studies also included voice and clarinet. In 1981, Mark was awarded a Bachelor of Music degree with distinction in both Piano Performance and Piano Pedagogy. He also received the award for the Most Outstanding Graduate. Postgraduate qualifications include a Graduate Diploma specialising in Piano Pedagogy, a Graduate Diploma in Further Education and Training and a Master of Music.
            </p>

            <p class="info">
                Mark founded and directed one of Australia’s largest and most successful music schools in 1983. He was on the staff of the Queensland University of Technology (QUT) for over 10 years where he passed on his considerable expertise in piano performance, music technology, keyboard harmony, aural training and musicianship studies.  He also directed the QUT Keyboard/Piano Ensemble.
            </p>

            <p class="info">
                Mark’s students have achieved great success nationally and internationally.  A testament to his skills is that many of Mark’s students have developed successful careers in the music profession as producers, composers, choral conductors, accompanists and teachers.
            </p>

            <p class="info">
                Expertise across a wide range of music genres defines Mark as a musician.  Concert engagements include having played under Werner Andreas Albert and as a soloist for Command Performances.  As a composer, Mark’s works are often heard in piano competitions and are featured in several examination syllabi as well as grade book publications for the AMEB.
            </p>

            <p class="info">
                Mark’s teaching enthusiasm is shared with children from as young as three years through to students studying piano at the highest diploma levels. In 1997 Mark established Encore Music Education and Accent Publishing with Morna Robinson.  Their most recent collaboration to produce Kinder Beat won an Australian Music Association award in 2010.  It continues to set a new global benchmark for early childhood music education.
            </p>

            <p class="heading-2">Morna Robinson</p>
            <p class="info-bold">AMusA, Diploma in Childcare Karitane Australia</p>

            <p class="info">
                With over 40 years of teaching experience, and professional qualifications in both music and childcare, Morna is an expert in early childhood music education.  She has a natural affinity with children and music, and has long been sought after by other teachers for advice and guidance.
            </p>
            <p class="info">
                Morna’s skills as a professional mentor are particularly evident through the key role she plays in assisting new and continuing Kinder Beat teachers to develop and refine their skills.  She is a founding director of Encore Music Education and Accent Publishing, and is an integral member of the Encore training team. Morna regularly shares her expertise at teacher training seminars in Australia and internationally. She is always practical in her approach and generous with her time.
            </p>
            <p class="info">
                An ongoing involvement with kindergartens and childcare centres has allowed Morna to continually research and evaluate how young children learn and respond to music and movement. Much of Morna’s research findings, together with her outstanding teaching strategies and creative approaches, are featured in the Encore Student Books and Teacher Guides.
            </p>
            <p class="info">
                In the 1970s, Morna studied singing at the Sydney Conservatorium of Music with the distinguished Elizabeth Todd OAM. She sang in the Sydney Philharmonia Motet under Peter Seymour, and at the opening ceremony for the Sydney Opera House. Morna was also a member of the Melbourne Chorale. After completing teacher training with the Yamaha Music Foundation, she taught in several Yamaha Music Schools in Melbourne and Brisbane.
            </p>
            <p class="info">
                Morna’s accomplishment as a singer is evident in her stylish, creative compositions.  In 1993 she started work on several of the songs that later became elements of the award-winning Kinder Beat program.  She has also written songs for specific occasions such as World Environment Day.  The musical concepts and imaginative nature of Morna’s compositions are central to the appeal and success of the Kinder Beat and Encore courses.
            </p>
            <p class="info">
                Morna’s chief instrument is the piano, and she has maintained a highly successful piano teaching studio for many years – never wavering in her commitment to excellence. From toddlers to higher grade piano students, Morna shares her empathy and musical skills with her students. She continues to achieve outstanding results because of her life-long dedication, generosity and passion for teaching.
            </p>

            <p class="heading">Our Team</p>

            <p class="heading-3">Mark Gibson | Managing Director</p>
            <p class="info qual">MMus, BMus(Dist), GradDipMus, GradDipFET, FTCL, LTCL, AMusA</p>
            <br>

            <p class="heading-3">Morna Robinson | Director</p>
            <p class="info qual">AMusA, Diploma in Childcare Karitane Australia</p>
            <br>

            <p class="heading-3">Joanna Gibson | Creative Lead</p>
            <p class="info qual">MMus, BMus(Hons), BAppSc(Dist), LMusA, AMusA, CMusA</p>
            <br>
        
            <p class="heading-3">Gloria Heasley | Office Manager</p>
            <p class="info">BCom(Marketing &amp; Management)</p>
            <br>
        
            <p class="heading-3">James Yelland | Website &amp; App Developer</p>
            <br>
            
            <p class="heading-3">Jane Gibson | Editor &amp; Proofreader</p>
            <p class="info qual">MEd, BA, DipEd</p>
            <br>

            <p class="heading-3">John Robinson | Stock Control &amp; Maintenance</p>
            <br>
        </div>
	</div>
</template>

<script>

import PhotoRow from '@/components/PhotoRow'

export default {
    components: {
        PhotoRow
    }
}
</script>
<template>
    <div class="photo-row">
        <img :src="url" v-for="(url, i) in images" :key="i">
    </div>
</template>

<script>
import { storage } from '@/firebase';

export default {
    data() {
        return {
            images: []
        }
    },
    props: {
        set: {
            type: Number,
            default: 1
        },
        max: {
            type: Number,
            default: 9
        }
    },
    mounted() {
        [...Array(this.max).keys()].forEach(i => {
            storage.ref(`home/set-${this.set}/${i + 1}.jpg`).getDownloadURL().then(url => {
                this.$set(this.images, i, url);
            });
        })
    }
}
</script>